import MessageBody from './MessageBody'
import { objectToQueryParams } from '@/utils'

export default class ServerLoggerService {
  public sendMessage (_body: MessageBody): Promise<void> {
    return new Promise((resolve: any) => {
      const body = new MessageBody(_body)
      const query = objectToQueryParams(body)
      const $img = document.createElement('img')
      $img.src = process.env.VUE_APP_API_URL + 'tech-check-logger' + query
      document.body.appendChild($img)

      setTimeout(() => {
        $img.remove()
        resolve()
      }, 1000)
    })
  }
}
