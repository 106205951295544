


















import to from 'await-to-js'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ChecklistItem, { Type, Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Tmplt_microphone from './Tmplt.microphone.vue'
import Tmplt_speakers from './Tmplt.speakers.vue'
import Tmplt_extension from './Tmplt.extension.vue'
import Tmplt_browser from './Tmplt.browser.vue'
import Tmplt_camera from './Tmplt.camera.vue'
import Tmplt_network from './Tmplt.network.vue'
import Tmplt_nativeApp from './Tmplt.nativeApp.vue'

@Component({
  components: {
    Tmplt_microphone,
    Tmplt_speakers,
    Tmplt_extension,
    Tmplt_browser,
    Tmplt_camera,
    Tmplt_network,
    Tmplt_nativeApp
  }
})
export default class ChecklistItemComp extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist
  @Prop() private audioEl: HTMLElement | undefined
  @Prop({default: 'auto'}) private mode!: 'manual' | 'auto'

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }
  private get status (): Status {
    return this.item.status
  }

  private get type (): Type {
    return this.item.type
  }
  private get priority (): Priority {
    return this.item.priority
  }

  private get templateComponent (): any {
    switch (this.type) {
      case 'microphone': return Tmplt_microphone
      case 'speakers': return Tmplt_speakers
      case 'extension': return Tmplt_extension
      case 'browser': return Tmplt_browser
      case 'camera': return Tmplt_camera
      case 'network': return Tmplt_network
      case 'native-app': return Tmplt_nativeApp
    }

    throw new Error(`Unsupported template '${this.type}'`)
  }

  public async check (): Promise<any> {
    let res: ChecklistItemValidationResult | any = this.$refs.templateComponent
      ? await (this.$refs.templateComponent as any).check(this.checklist)
      : undefined

    if (this.status === 'none') {
      this.item.validationResult = null
      this.item.status = 'none'
      return
    }

    if (!res) {
      res = new ChecklistItemValidationResult({status: 'success'})
    }

    this.item.validationResult = res
    this.item.status = res.status

    return res
  }

  public reset (soft: boolean = true): void {
    const $ref: any = this.$refs.templateComponent || undefined
    if (!$ref) return undefined
    $ref.reset(soft)
  }

  @Watch('item.status')
  private onStatusChanged () {
    this.$emit('update:status', this.item)
  }
}
