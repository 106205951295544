




























import DetectRTC from 'detectrtc'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Howl } from 'howler'
import { wait } from '@/utils'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import ChecklistComp from '../Checklist.vue'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'
import DialogMessage from './_dialogMessage.vue'

@Component({
  components: {
    Title,
    ValidationMessage,
    DialogMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist
  @Prop() private audioEl!: HTMLElement
  private isShowDialog: boolean = false
  private handlerClickYes: (() => void) | undefined
  private handlerClickNo: (() => void) | undefined
  private defaultPlayer: any | undefined

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (status) {
    this.item = {...this.item, status}
  }
  private get priority (): Priority {
    return this.item.priority
  }
  private get $audio (): any {
    return this.audioEl
  }

  public check (): Promise<ChecklistItemValidationResult> {
    return this.checkMicRecord()
  }

  public checkMicRecord (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    return new Promise(async (resolve) => {
      this.isShowDialog = true

      this.handlerClickYes = () => {
        this.isShowDialog = false
        this.stopPlayingAudio()
        // this.setMicrophoneItemStatus('success')

        const res = new ChecklistItemValidationResult({
          status: 'success',
          data: {
            hasSpeakers: DetectRTC.hasSpeakers,
            hasVoiceRecordBlob: !!this.getVoiceRecordBlob()
          }
        })
        resolve(res)
      }
      this.handlerClickNo = () => {
        this.isShowDialog = false
        this.stopPlayingAudio()
        // this.setMicrophoneItemStatus('error', 'Microphone missing')

        const res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => this.$t('msg.speakers_missing'),
          data: {
            hasSpeakers: DetectRTC.hasSpeakers,
            hasVoiceRecordBlob: !!this.getVoiceRecordBlob()
          }
        })

        resolve(res)
      }

      this.startPlayingAudio()
    })
  }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {},
        validationResult: null
      })
    }

    this.stopPlayingAudio()
    this.isShowDialog = false
    this.handlerClickYes = undefined
    this.handlerClickNo = undefined
    this.defaultPlayer = undefined
  }

  private setMicrophoneItemStatus (status: Status, message?: string, data?: any): void {
    this.$emit('update:externalItem', {
      type: 'microphone',
      data: {
        status,
        validationResult: message
          ? new ChecklistItemValidationResult({status, message, data})
          : null
      }
    })
  }

  private getVoiceRecordBlob (): Blob | any {
    const items = this.checklist.items
    const micItem = items.find((item) => item.type === 'microphone')
    const voiceRecordBlob: Blob | any = micItem && micItem.cache.voiceRecordBlob
    return voiceRecordBlob
  }

  private async startPlayingAudio (): Promise<void> {
    const voiceRecordBlob: Blob | any = this.getVoiceRecordBlob()

    if (voiceRecordBlob) return this.startPlayingRecordedAudio()
    else return this.startPlayingAutoGeneratedAudio()
  }

  private async stopPlayingAudio (): Promise<void> {
    this.stopPlayingRecordedAudio()
    this.stopPlayingAutoGeneratedAudio()
  }

  private async startPlayingRecordedAudio (): Promise<void> {
    const $audio: any = this.$audio
    const voiceRecordBlob: Blob | any = this.getVoiceRecordBlob()
    let url: string = ''

    try {
      url = webkitURL.createObjectURL(voiceRecordBlob)
    } catch (err) {
      // Firefox
      url = URL.createObjectURL(voiceRecordBlob)
    }

    if (!url) {
      console.error('Could not make URL from the recorded sound')
      return this.startPlayingAutoGeneratedAudio()
    }

    this.defaultPlayer = new Howl({
      src: [url],
      format: ['mpeg', 'oog'],
      html5: true,
      autoplay: true,
      loop: true,
      volume: 0.5,
      onplayerror: (code: any) => {
        this.defaultPlayer.once('unlock', () => this.defaultPlayer.play())
      }
    })

    this.defaultPlayer.once('load', () => {
      this.defaultPlayer.play()
    })
    this.defaultPlayer.once('loaderror', (code1: any, code2: any, msg: any) => {
      console.error('Recorded audio: loaderror: ', code1, code2, msg)
    })
    this.defaultPlayer.once('playerror', (code1: any, code2: any, msg: any) => {
      console.error('Recorded audio: playerror: ', code1, code2, msg)
    })

    // $audio.src = url
    // $audio.volume = 1
    // $audio.muted = false
    // $audio.autoplay = true
    // $audio.controls = false
    // $audio.currentTime = 0
    // $audio.onended = function repeat () {
    //   $audio.play()
    //   $audio.currentTime = 0
    // }

    // $audio.load()
    // $audio.play()
  }

  private stopPlayingRecordedAudio (): void {
    // const $audio: any = this.$audio
    // $audio.pause()
    // $audio.src = ''
    // $audio.load()
    // $audio.currentTime = 0
    if (!this.defaultPlayer) return
    this.defaultPlayer.stop()
    this.defaultPlayer.unload()
    this.defaultPlayer = undefined
  }

  private async startPlayingAutoGeneratedAudio (): Promise<void> {
    this.defaultPlayer = new Howl({
      src: ['/audio/default_sound.webm', '/audio/default_sound.mp3'],
      format: ['webm', 'mp3'],
      html5: true,
      autoplay: true,
      loop: true,
      volume: 0.5,
      onplayerror: () => {
        this.defaultPlayer.once('unlock', () => this.defaultPlayer.play())
      }
    })

    this.defaultPlayer.once('load', () => {
      this.defaultPlayer.play()
    })
    this.defaultPlayer.once('loaderror', (code1: any, code2: any, msg: any) => {
      console.error('Default sound: loaderror: ', code1, code2, msg)
    })
    this.defaultPlayer.once('playerror', (code1: any, code2: any, msg: any) => {
      console.error('Default sound: playerror: ', code1, code2, msg)
    })
  }

  private async stopPlayingAutoGeneratedAudio (): Promise<void> {
    if (!this.defaultPlayer) return
    this.defaultPlayer.stop()
    this.defaultPlayer.unload()
    this.defaultPlayer = undefined
  }
}
