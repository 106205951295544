export const wait = (timeout: number) => new Promise(r => setTimeout(r, timeout))

export function askEnableVideo (): Promise<boolean> {
  return navigator.mediaDevices.getUserMedia({
      video: true
    })
    .then((stream) => {
      stream.getTracks().forEach((track) => track.stop())
      return true
    })
    .catch(() => false)
}

export function askEnableAudio (): Promise<any> {
  return navigator.mediaDevices.getUserMedia({
      audio: true
    })
    .then((stream) => {
      stream.getTracks().forEach((track) => track.stop())
      return true
    })
    .catch(() => false)
}

export function checkIfChromeExtensionInstalled (extensionId: string): Promise<boolean> {
  return fetch(`chrome-extension://${extensionId}/manifest.json`)
    .then(r => r.json())
    .then(r => {
      console.log('fetch version: ', r && r.version)
      return true
    })
    .catch((err) => {
      console.error(err)
      return false
    })

  // TODO: not in use
  // return new Promise(resolve => {
  //   try {
  //     window.chrome.runtime.sendMessage(extensionId, 'version', (res: any) => {
  //       console.log('sendMessage res: ', res)
  //       resolve(true)
  //     })
  //   } catch (err) {
  //     console.error(err)
  //     resolve(false)
  //   }
  // })
}

export function isIOS (): boolean {
  return /(iPhone|iPod|iPad)/i.test(navigator.platform)
}

export function isMac (): boolean {
  return navigator.platform.indexOf('Mac') > -1
}

export function isWindows (): boolean {
  return navigator.platform.indexOf('Win') > -1
}

export function isLinux (): boolean {
  return navigator.platform.indexOf('Linux') > -1
}

export function isAndroid (): boolean {
  return navigator.platform.indexOf('Android') > -1
}

export function getOSName (): 'iOS' | 'Mac' | 'Windows' | 'Linux' | 'Android' | 'Unknown' {
  if (isIOS()) return 'iOS'
  if (isMac()) return 'Mac'
  if (isWindows()) return 'Windows'
  if (isLinux()) return 'Linux'
  if (isAndroid()) return 'Android'

  return 'Unknown'
}

export interface ChromeVersion {
  full: string
  major: number
  minor: number
  build: number
  patch: number
  osName: string
}
export function getChromeLatestVersion (): Promise<ChromeVersion> {
  const osName = getOSName()

  return fetch(`https://chromiumdash.appspot.com/fetch_releases?channel=Stable&platform=${osName}&num=1&offset=0`)
    .then(r => r.json())
    .then((res: any[]) => {
      const latestRelease = res[0]
      const version = latestRelease ? latestRelease.version : ''
      const parts = version.split('.')
      const major = +parts[0]
      const minor = +parts[1]
      const build = +parts[2]
      const patch = +parts[3]

      return {
        full: version,
        major,
        minor,
        build,
        patch,
        osName
      }
    })
}


// kbps
export type NetworkSpeed = number

export function getDownloadSpeed (): Promise<{kbps: NetworkSpeed}> {
  return new Promise((resolve, reject) => {
    let startTime: number

    // TODO: use a large file placed on our server
    let url = 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Fronalpstock_big.jpg'

    const download = new Image()
    download.onerror = reject
    download.onload = async (a) => {
      const imgSizeB = await getImageSizeInBytes(url)
      const duration: number = (+new Date() - startTime) / 1000
      const bitsLoaded: number = imgSizeB * 8
      const speedBps: number = +(bitsLoaded / duration).toFixed(2)
      const speedKbps: number = +(speedBps / 1024).toFixed(2)

      resolve({kbps: speedKbps})
    }

    startTime = +new Date()
    download.src = url = url + '?_cache=' + Math.floor(Math.random() * 1e5)
  })
}

export function getUploadSpeed (iterations = 5): Promise<{kbps: NetworkSpeed}> {
  const results: Array<{kbps: NetworkSpeed}> = []

  return new Array(iterations)
    .fill(0)
    .reduce(
      (seq) => seq.then(() => {
        return _requestPromise()
          .then((res) => results.push(res))
      }),
      Promise.resolve()
    )
    .then(() => {
      const sum = results.reduce((memo, speed) => memo += speed.kbps, 0)
      const avg = sum / iterations
      return {kbps: avg}
    })

  function _requestPromise (): Promise<{kbps: NetworkSpeed}> {
    return new Promise((resolve) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest()
      let startTime: number

      // TODO: use a large file placed on our server
      let url: string = 'https://images.google.com/searchbyimage/upload'
      url += '?_cache=' + Math.floor(Math.random() * 1e5)
      const data: string = getRandomStringInMb(25)

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return

        const duration: number = (+new Date() - startTime) / 1000
        const bitsLoaded: number = data.length
        const speedBps: number = +(bitsLoaded / duration).toFixed(2)
        const speedKbps: number = +(speedBps / 1024).toFixed(2)

        resolve({kbps: speedKbps})
      }

      xhr.open('POST', url, true)
      startTime = +new Date()
      xhr.send(data)
    })
  }
}

export function getRandomStringInMb (sizeInMb: number): string {

  // random data prevents gzip effect
  const chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]\{}|\':,./<>?'
  const length: number = sizeInMb * 1024 * 1024
  let result: string = ''
  for (let index = 0; index < length; index++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export function getImageSizeInBytes (imgURL: string): Promise<number> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('HEAD', imgURL, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return
      if (xhr.status !== 200) return reject(xhr)
      const length: number = +(xhr.getResponseHeader('Content-Length') || 0)
      resolve(length)
    }
    xhr.send(null)
  })
}

declare var SomApi: any
SomApi.account = process.env.VUE_APP_SPEED_OF_API_KEY
SomApi.domainName = window.location.host
SomApi.config.latencyTestEnabled = false
SomApi.config.uploadTestEnabled = true
SomApi.config.userInfoEnabled = false
SomApi.config.progress = {
  enabled: true,
  verbose: false
}

export interface NetworkTestResult {
  download: number
  hostname: string
  ip_address: string
  jitter: number
  latency: number
  maxDownload: number
  maxUpload: number
  testDate: string
  testServer: string
  upload: number
  userAgent: string
}

export interface ProgressObject {
  type: string
  pass: string
  percentDone: number
  currentSpeed: number
}
type ProgressHandler = (data: ProgressObject) => void

export function runNetworkTestSpeedOfMe (sustainTime = 4, progressHandler: undefined | ProgressHandler): Promise<NetworkTestResult> {
  return new Promise((resolve, reject) => {
    SomApi.config.sustainTime = sustainTime
    SomApi.onTestCompleted = (r: NetworkTestResult) => _end() && resolve(r)
    SomApi.onError = (r: any) => _end() && reject(r)

    if (progressHandler) {
      SomApi.onProgress = progressHandler
    }

    SomApi.startTest()

    function _end (): boolean {
      SomApi.onTestCompleted = undefined
      SomApi.onError = undefined
      SomApi.onProgress = undefined
      return true
    }
  })
}

export function objectToQueryParams (queryParams: object): string {
  return queryParams ?
    Object.entries(queryParams).reduce((acc, [key, val], index) => {
      const sign = index === 0 ? '?' : '&'
      acc += `${sign}${encodeURIComponent(key)}=${encodeURIComponent(val)}`
      return acc
    }, '')
    : ''
}
