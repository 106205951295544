
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ChecklistItemPart extends Vue {
  @Prop({default: ''}) private size?: string
}
