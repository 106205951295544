import ChecklistItemValidationResult from './ChecklistItemValidationResult'

export default class ChecklistItem {
  public label: string|(() => string) = ''
  public status: Status = 'none'
  public isHidden: boolean | (() => boolean) = false
  public type: Type
  public priority: Priority = 'optional'
  public cache: {[key: string]: any} = {}
  public options: {[key: string]: any} = {}
  public validationResult: ChecklistItemValidationResult | null = null

  constructor (data: any) {
    this.label = data.label
    this.status = data.status || 'none'
    this.isHidden = typeof data.isHidden === 'function'
      ? data.isHidden
      : data.isHidden === true
    this.type = data.type
    this.priority = data.priority || 'optional'
    this.cache = data.cache || {}
    this.options = data.options || {}
    this.validationResult = data.validationResult || null
  }
}

export type Type =  'os/version' |
  'browser' |
  'device' |
  'camera' |
  'microphone' |
  'speakers' |
  'extension' |
  'native-app' |
  'network'

export type Status = 'none' | 'checking' | 'success' | 'warning' | 'error' | 'info'

export type Priority =

  // wouldn't work without
  'required' |

  // works without, but with limitations
  'optional'
