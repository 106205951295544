export default {
  brand_name: "TestingTime",
  app_name: "TechChecker",

  yes: "Oui",
  no: "Non",
  check: "Vérifier",
  stop: "Arrêter",
  works: "Ca marche!",
  doesnt_work: "Ca marche pas",

  check_my_device_for_title: "Vérifier mon appareil pour:",
  enable_app_mode: "Activer le mode {mode}",
  start_camera_test: "Démarrer le test de la caméra",
  start_test_recording: "Démarrer le test d'enregistrement",
  choose_service: "Choisir un service",

  browser: "Navigateur internet",
  camera: "Caméra",
  microphone: "Microphone",
  loudspeakers: "Haut-parleurs",
  chrome_extension: "Extension Chrome",
  native_app: "Application native",
  network: "Réseau internet",

  msg: {
    something_went_wrong: "Quelque chose n'a pas marché",
    logs_copied_to_clipboard: "Les logs ont été copiés avec succès dans le presse-papiers !",
    allow_camera_access_title: "Veuillez autoriser l'accès à votre caméra",
    allow_camera_access_description: "Nous en avons besoin pour pouvoir vérifier si vous pouvez filmer avec votre caméra. Les données ne seront ni enregistrées ni envoyées à un serveur externe.",
    you_got_not_latest_chrome_version: "La dernière version de Chrome pour {osName} est {latestVersion} mais vous avez {currentVersion}.",
    cannot_get_access_to_test_service: "Impossible d'avoir accès au service de test",
    please_install_link: "Veuillez installer {link}",
    you_are_offline: "Vous êtes déconnecté",
    did_you_hear_question: "Avez-vous entendu ?",
    speak_for_num_seconds: "Veuillez parler pendant {num} secondes",

    check_your_connection: "Assurez-vous d'avoir une connexion stable.",
    we_recommend_num_mbps_upload_n_download: "Nous recommandons de disposer d'au moins {num}MBps pour le téléchargement et le chargement de données.",
    using_mobile_type_connection_not_recommended: "L'utilisation d'une connexion mobile {type} n'est pas recommandée.",

    latest_version_of_chrome: "la dernière version de Chrome",
    your_chrome_is_out_of_date: "Votre Chrome n'est pas à jour présentement.",
    unsupported_browser: "Le navigateur {nom} n'est pas supporté.",
    item_not_supported_browser: "{item} n'est pas entièrement supporté par ce navigateur.",
    cannot_get_access_to_browsers_navigator: "Impossible d'accéder au navigateur internet",

    camera_missing: "Caméra manquante",
    microphone_missing: "Microphone manquant",
    speakers_missing: "Haut-parleurs manquants",

    installed_question: "L'avez vous installé?",
    check_for_installation_here: "Veuillez vérifier l'installation ici.",

    your_device_not_ready: "Votre appareil n'est pas prêt !",
    your_device_ready_with_limitations: "Votre appareil est prêt mais avec des limitations.",
    your_device_ready_for_test: "Bravo ! Votre appareil est prêt pour le test utilisateur.",

    result_instructions_check_email: "Dès qu'il sera temps, veuillez cliquer sur le lien \"informations d'appel\" dans vos emails pour participer. Vous pouvez maintenant fermer cet onglet."
  },

  what_to_do: {
    title: "Que faire maintenant ?",

    link_instructions_on_item: "instructions sur {item}",

    steps: {
     1: "Essayez de résoudre chaque problème comme indiqué ci-dessus. Ensuite, relancez la vérification.",
     2: "Si vous avez encore des problèmes, lisez notre {link} et essayez de les résoudre en conséquence.",
     3: "Si rien ne vous aide, veuillez nous envoyer un email à {link}.",
    }
  }
}
