












































































































import 'reflect-metadata'
import _get from 'lodash.get'
import { Howler } from 'howler'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ServerLoggerService from '@/services/ServerLoggerService'
import Checklist from '@/classes/Checklist'
import { Status, Type } from '@/classes/ChecklistItem'
import CONFIG from '@/CONFIG'
import ComponentChecklist from './Checklist.vue'

const AudioContext = window.AudioContext || (window as any).webkitAudioContext
let globalAudioContext: any = null

@Component({
  components: {
    Checklist: ComponentChecklist
  }
})
export default class TechChecker extends Vue {
  @Prop() private activeServiceId!: string
  @Prop() private checklists!: {[key: string]: Checklist}
  @Prop({default: 'auto'}) private mode!: 'manual' | 'auto'
  private isChecking: boolean = false
  private result: {status: 'success'|'warning'|'error', msg: () => string} | null = null

  private get links (): {[key: string]: string} {
    const serviceInstructions = CONFIG.CHECKLISTS[this.activeServiceId].links.instructions
    return {
      email: `mailto:${process.env.VUE_APP_LINK_EMAIL_HELP}`,
      instructions: typeof serviceInstructions === 'function'
        ? serviceInstructions()
        : serviceInstructions
    }
  }
  private helpEmail: string = process.env.VUE_APP_LINK_EMAIL_HELP

  private get whatToDoInstructions () {
    const items: any[] = []

    items.push(this.$t('what_to_do.steps.1'))
    items.push(this.$t('what_to_do.steps.2', {
      link: `<a
        href="${this.links.instructions}"
        target="blank"
        rel="external">
        ${this.$t('what_to_do.link_instructions_on_item', {
          item: CONFIG.CHECKLISTS[this.activeServiceId].service.name
        })}</a>`
    }))
    items.push(this.$t('what_to_do.steps.3', {
      link: `<a
        href="${this.links.email}"
        target="blank"
        rel="external">${this.helpEmail}</a>`
    }))

    return items
  }

  private mounted () {
    Howler.autoUnlock = false
    const $checkBtn = this.$refs.checkBtn as HTMLElement
    const $stopBtn = this.$refs.stopBtn as HTMLElement
    const clickEvnt: string = ('ontouchstart' in document.documentElement)
      ? 'touchend'
      : 'click'

    $checkBtn.addEventListener(clickEvnt, this.onClickCheck, true)
    $stopBtn.addEventListener(clickEvnt, this.onClickStop, true)
  }

  private get activeCheckList (): Checklist | undefined {
    return this.activeServiceId
      ? this.checklists[this.activeServiceId]
      : undefined
  }

  private onClickCheck (): void {

    /**
     * Hack:
     * iOS requires that audio play be initiated as part of user interaction.
     * This function should be called from a user's action handler and
     * after that we can play any audio on the page
     */
    const $audio: any = this.$refs.audio
    $audio.src = ''
    $audio.load()

    if (!globalAudioContext) {
      globalAudioContext = new AudioContext()
      globalAudioContext.createMediaElementSource(this.$refs.audio as any)
    }

    Howler.ctx = globalAudioContext
    Howler._unlockAudio()

    setTimeout(() => this.runChecking())
  }

  private async runChecking (checkItems?: Type[]): Promise<any> {
    console.info(`Run checking: ${this.activeServiceId} (${this.mode})`)

    this.isChecking = true
    this.result = null

    if (!this.activeCheckList || !this.$refs.checklist) {
      this.isChecking = false
      return
    }

    this.reset(false)
    await (this.$refs.checklist as ComponentChecklist).check(checkItems)

    this.updateResultMessage()
    console.info(`End checking: ${_get(this.result, 'status')}`)

    this.sendResultLogs()
    this.isChecking = false
  }

  private onUpdateItemStatus (): void {
    if (this.isChecking) return
    this.updateResultMessage()
  }

  private onClickStop ($e?: any): void {
    this.isChecking = false
    this.reset(false)
  }

  @Watch('mode')
  private onChangeMode (): void {
    this.onClickStop()
  }

  @Watch('activeServiceId')
  private reset (soft: boolean = true): void {
    this.$refs.checklist && (this.$refs.checklist as ComponentChecklist).reset(soft)
  }

  @Watch('activeServiceId')
  private updateResultMessage (): void {
    if (!this.activeCheckList || !this.$refs.checklist) {
      this.result = null
      return
    }

    const counters = {
      none: 0,
      error: 0,
      warning: 0,
      checking: 0,
      success: 0,
      info: 0
    }

    for (const item of (this.$refs.checklist as any).items) {
      counters[item.status as Status]++

      if (item.status === 'none') break
      if (item.status === 'error') break
    }

    // when some of checks are not completed
    if (counters.checking || counters.none) {
      this.result = null
      return
    }

    if (counters.error) {
      this.result = {
        status: 'error',
        msg: () => this.$t('msg.your_device_not_ready') as string
      }
      return
    }

    if (counters.warning) {
      this.result = {
        status: 'warning',
        msg: () => this.$t('msg.your_device_ready_with_limitations') as string
      }
      return
    }

    this.result = {
      status: 'success',
      msg: () => this.$t('msg.your_device_ready_for_test') as string
    }
  }

  private sendResultLogs (): void {
    if (process.env.NODE_ENV !== 'production') return

    const search = new URLSearchParams(window.location.search)
    const query = Object.fromEntries(search)

    ServerLoggerService.sendMessage({
      userId: query.userId,
      lineupId: query.lineupId,
      serviceName: this.activeServiceId as any,
      status: _get(this.result, 'status') as any,
      logs: _get(console, 'getLogs', () => '')()
    })
  }
}
