




























import DetectRTC from 'detectrtc'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { checkIfChromeExtensionInstalled } from '@/utils'
import BrowserExtension from '@/types/BrowserExtension'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'
import DialogMessage from './_dialogMessage.vue'

@Component({
  components: {
    Title,
    ValidationMessage,
    DialogMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist
  private isShowDialog: boolean = false
  private handlerClickYes: (() => void) | undefined
  private handlerClickNo: (() => void) | undefined

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (val) {
    this.$emit('input', {...this.item, status: val})
  }
  private get priority (): Priority {
    return this.item.priority
  }

  public check (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    return new Promise(async (resolve) => {
      let res

      const browserName: string = DetectRTC.browser.name.toLowerCase()
      const extension: BrowserExtension | undefined = this.item.options &&
        this.item.options.extension

      if (!extension) return resolve()

      if (browserName !== extension.browser) {
        res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => this.$t('msg.unsupported_browser', {name: DetectRTC.browser.name}),
          data: {
            extension
          }
        })
        return resolve(res)
      }

      this.isShowDialog = true

      this.item.validationResult = new ChecklistItemValidationResult({
        status: 'info',
        message: () => `<a href="${extension.url}" class="text--info" target="blank" rel="external noopener">${this.$t('msg.check_for_installation_here')}</a>`,
        data: {
          extension
        }
      })

      this.handlerClickYes = () => {
        this.isShowDialog = false
        res = new ChecklistItemValidationResult({status: 'success', data: {extension}})
        resolve(res)
      }
      this.handlerClickNo = () => {
        this.isShowDialog = false
        res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => this.$t('msg.please_install_link', {
            link: `<a href="${extension.url}" target="blank" rel="external noopener">${extension.label}</a>`
          }),
          data: {
            extension
          }
        })
        resolve(res)
      }
    })
  }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {},
        validationResult: null
      })
    }
    this.handlerClickYes = undefined
    this.handlerClickNo = undefined
    this.isShowDialog = false
  }
}
