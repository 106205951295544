




































import to from 'await-to-js'
import _merge from 'lodash.merge'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DetectRTC from 'detectrtc'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Checklist from '@/classes/Checklist'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'
import DialogMessage from './_dialogMessage.vue'

import ChecklistItemComp from './index.vue'
declare const MediaRecorder: any

@Component({
  components: {
    Title,
    ValidationMessage,
    DialogMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist
  @Prop({default: 'auto'}) private mode!: 'manual' | 'auto'
  private isRecording: boolean = false
  private isRepeatChecking: boolean = false
  private handlerClickTest: (() => void) | undefined

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (val) {
    this.item = {...this.item, status: val}
  }
  private get priority (): Priority {
    return this.item.priority
  }
  private get allowRecordTest (): boolean {
    return this.item.options && this.item.options.allowRecordTest === true
  }
  private set allowRecordTest (val) {
    this.item = _merge({}, this.item, {options: {allowRecordTest: val}})
  }
  private get recordingLengthMs (): number {
    return (this.item.options && this.item.options.recordingLengthMs) || 3000
  }
  private get isButtonsDisabled (): boolean {
    return this.checklist.status === 'checking' && this.status !== 'checking'
  }

  /**
   * Mobile Safari doesn't expose "audioinput" kind of media devices.
   * This is a known limitation.
   */
  public check (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    return new Promise(async (resolve) => {
      if (this.mode === 'manual') {
        this.allowRecordTest = true

        this.handlerClickTest = async () => {
          this.allowRecordTest = false
          const res = await this.runTest()
          resolve(res)
        }
      } else {
        const res = await this.runTest()
        resolve(res)
      }

      // TODO: not in use
      // if (!DetectRTC.hasMicrophone) {
      //   res = new ChecklistItemValidationResult({
      //     status: 'warning',
      //     message: '',
      //     data: {
      //       hasMicrophone: DetectRTC.hasMicrophone
      //     }
      //   })
      //   return resolve(res)
      // }
    })
  }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {
          voiceRecordBlob: null,
          voiceRecordLengthMs: null
        },
        options: {
          allowRecordTest: false
        },
        validationResult: null
      })
    }
    this.isRecording = false
    this.isRepeatChecking = false
    this.handlerClickTest = undefined
  }

  private async onClickTest (): Promise<void> {
    const $checklist: any = this.$parent.$parent
    if (!$checklist) return

    this.reset(false)
    this.$emit('reset:externalItem', {type: 'speakers', soft: false})

    const res: ChecklistItemValidationResult = await this.runTest()
    this.status = res.status
    $checklist.check(['speakers'])
  }

  private runTest (): Promise<ChecklistItemValidationResult> {
    return new Promise(async (resolve) => {
      let res
      this.status = 'checking'
      const [err] = await to(this.recordAudio())

      if (err) {
        if (!DetectRTC.isWebsiteHasMicrophonePermissions) {
          res = new ChecklistItemValidationResult({
            status: this.priority === 'required' ? 'error' : 'warning',
            message: () => this.$t('msg.microphone_missing'),
            data: {
              hasMicrophone: DetectRTC.hasMicrophone,
              isWebsiteHasMicrophonePermissions: DetectRTC.isWebsiteHasMicrophonePermissions
            }
          })
        } else {
          res = new ChecklistItemValidationResult({
            status: this.priority === 'required' ? 'error' : 'warning',
            message: () => err && err.message ? err.message : this.$t('msg.something_went_wrong'),
            data: {
              err,
              hasMicrophone: DetectRTC.hasMicrophone,
              isWebsiteHasMicrophonePermissions: DetectRTC.isWebsiteHasMicrophonePermissions
            }
          })
        }

        return resolve(res)
      }

      res = new ChecklistItemValidationResult({
        status: 'success',
        data: {
          hasMicrophone: DetectRTC.hasMicrophone,
          isWebsiteHasMicrophonePermissions: DetectRTC.isWebsiteHasMicrophonePermissions
        }
      })
      resolve(res)
    })
  }

  private recordAudio (): Promise<any> {
    const _navigator: any = window.navigator

    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (_navigator.mediaDevices === undefined) _navigator.mediaDevices = {}

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (_navigator.mediaDevices.getUserMedia === undefined) {
      _navigator.mediaDevices.getUserMedia = (constraints: any) => {
        const getUserMedia = _navigator.webkitGetUserMedia || _navigator.mozGetUserMedia

        if (!getUserMedia) {
          console.error('getUserMedia() is not implemented in this browser.')
          return Promise.reject({
            message: this.$t('msg.item_not_supported_browser', {item: 'WebRTC'})
          })
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(_navigator, constraints, resolve, reject)
        })
      }
    }

    return _navigator.mediaDevices
      .getUserMedia({audio: true})
      .then((stream: any) => {
        return new Promise((resolve) => {
          const options = {mimeType: 'audio/webm'}
          const recordedChunks: any[] = []
          const mediaRecorder = new MediaRecorder(stream, options)

          mediaRecorder.addEventListener('dataavailable', (e: any) => {
            if (e.data.size > 0) recordedChunks.push(e.data)
          })

          mediaRecorder.addEventListener('stop', () => {
            this.item.cache.voiceRecordBlob = new Blob(recordedChunks, {type: 'audio/mpeg'})
            this.item.cache.voiceRecordLengthMs = this.recordingLengthMs
            stream.getTracks().forEach((track: any) => track.stop())
            this.isRecording = false
            resolve()
          })

          this.isRecording = true
          mediaRecorder.start()
          setTimeout(() => mediaRecorder.stop(), this.recordingLengthMs)
        })
      })
  }
}
