


















import _merge from 'lodash.merge'
import DetectRTC from 'detectrtc'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { wait } from '@/utils'
import Checklist, { Status } from '@/classes/Checklist'
import ClassChecklistItem, { Type } from '@/classes/ChecklistItem'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import ChecklistItem from './ChecklistItem/index.vue'

@Component({
  components: {
    ChecklistItem
  }
})
export default class ChecklistComp extends Vue {
  @Prop() public value!: Checklist
  @Prop({default: 'auto'}) public mode!: 'manual' | 'auto'
  public items: ClassChecklistItem[] = []

  public get checklist (): Checklist {
    return this.value
  }
  public get status (): Status {
    return this.checklist.status
  }
  public set status (status: Status) {
    this.$emit('change:status', status)
  }
  public get $audio (): any {
    return this.$parent.$refs.audio
  }

  public check (checkItems?: Type[]): Promise<void> {
    if (this.status === 'checking') return Promise.resolve()

    return new Promise((resolve) => {
      this.status = 'checking'

      DetectRTC.load(async () => {
        let i = 0

        while (i < this.items.length) {
          if (this.status === 'none') break

          const item = this.items[i]

          if (Array.isArray(checkItems)) {
            if (checkItems.indexOf(item.type) === -1) {
              i++
              continue
            }
          }

          const $ref: ChecklistItem = (this.$refs[`item--${item.type}`] as ChecklistItem[])[0]

          if (!$ref) {
            i++
            continue
          }

          await wait(100)
          const res: ChecklistItemValidationResult = await $ref.check()

          if (res) {
            console.info(`Item ${item.type}:${item.priority}:${res.status}`, res.data)
          }

          if (res && typeof res.step === 'number') {
            if (res.step < 0) {
              i -= res.step
              continue
            }
            if (res.step > 0) {
              i += res.step
              continue
            }
          }

          i++
        }

        this.status = 'completed'
        resolve()
      })
    })
  }

  public reset (soft: boolean = true): void {
    this.status = 'none'

    for (const item of this.items) {
      const $ref: ChecklistItem = (this.$refs[`item--${item.type}`] as ChecklistItem[])[0]
      if (!$ref) continue
      $ref.reset(soft)
    }
  }

  private mounted (): void {
    DetectRTC.load(async () => {
      this.items = this.getItems()
    })
  }

  private getItems (): ClassChecklistItem[] {
    return this.checklist.items.filter(item => {
      if (item.isHidden === true) return false
      if (typeof item.isHidden === 'function') {
        if (item.isHidden()) return false
      }
      return true
    })
  }

  private onResetItem (e: {type: Type, soft: boolean}): void {
    const $ref: ChecklistItem = (this.$refs[`item--${e.type}`] as ChecklistItem[])[0]
    if (!$ref) return
    $ref.reset(e.soft)
  }

  private onUpdateItem (e: {type: Type, data: ClassChecklistItem} | undefined): void {
    if (!e) return
    const i: number = this.checklist.items
      .findIndex(itm => itm.type === e.type)
    const item: ClassChecklistItem | undefined = i !== -1
      ? this.checklist.items[i]
      : undefined

    if (!item) return
    this.checklist.items.splice(i, 1, _merge(item, e.data))
  }
  private onUpdateItemStatus (item: ClassChecklistItem): void {
    this.$emit('update:item:status', item)
  }
}
