







import DetectRTC from 'detectrtc'
import { getChromeLatestVersion, ChromeVersion } from '@/utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import BrowserExtension from '@/types/BrowserExtension'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'

interface BrowserOptions {
  browser: 'chrome'
  isAllowAnyMobileBrowser: boolean
}

@Component({
  components: {
    Title,
    ValidationMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (val) {
    this.$emit('input', {...this.item, status: val})
  }
  private get priority (): Priority {
    return this.item.priority
  }

  public check (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    let res
    const options = this.item.options as BrowserOptions

    if (options.isAllowAnyMobileBrowser && DetectRTC.isMobileDevice) {
      res = new ChecklistItemValidationResult({
        status: 'success',
        data: {
          isMobileDevice: DetectRTC.isMobileDevice,
          browser: DetectRTC.browser
        }
      })
      return Promise.resolve(res)
    }

    if (options.browser === 'chrome') {
      return this.checkChrome()
    }

    throw new Error(
      this.$t('msg.unsupported_browser', {name: options.browser}) as string
    )
  }

  public checkChrome (): Promise<ChecklistItemValidationResult> {
    return new Promise(async (resolve) => {
      let res
      const CHROME_LINK = 'https://www.google.com/chrome'

      if (!DetectRTC.browser.isChrome) {
        res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => [
            this.$t('msg.unsupported_browser', {name: DetectRTC.browser.name}),
            this.$t('msg.please_install_link', {
              link: `<a href="${CHROME_LINK}" external>${this.$t('msg.latest_version_of_chrome')}</a>`
            })
          ].join(' '),
          data: {
            browser: DetectRTC.browser
          }
        })
        return resolve(res)
      }

      const currentVersion: string = DetectRTC.browser.fullVersion || ''
      const parts: string[] = currentVersion.split('.')
      const major = +parts[0]
      const minor = +parts[1]

      const latestVersion: ChromeVersion = await getChromeLatestVersion()
      const majorDif = latestVersion.major - major

      if (major < latestVersion.major) {
        res = new ChecklistItemValidationResult({
          status: majorDif > 2 ? 'error' : 'warning',
          message: () => [
            this.$t('msg.your_chrome_is_out_of_date'),
            this.$t('msg.you_got_not_latest_chrome_version', {
              osName: latestVersion.osName,
              latestVersion: latestVersion.full,
              currentVersion
            }),
            this.$t('msg.please_install_link', {
              link: `<a href="${CHROME_LINK}" external>${this.$t('msg.latest_version_of_chrome')}</a>`
            })
          ].join(' '),
          data: {
            browser: DetectRTC.browser,
            currentVersion,
            latestVersion: latestVersion.full
          }
        })
        return resolve(res)
      }

      res = new ChecklistItemValidationResult({
        status: 'success',
        data: {
          browser: DetectRTC.browser,
          currentVersion,
          latestVersion: latestVersion.full
        }
      })
      return resolve(res)
    })
  }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {},
        validationResult: null
      })
    }
  }
}
