export default {
  brand_name: "TestingTime",
  app_name: "TechChecker",

  yes: "Yes",
  no: "No",
  check: "Check",
  stop: "Stop",
  works: "Works!",
  doesnt_work: "Doesn't",

  check_my_device_for_title: "Check my device for:",
  enable_app_mode: "Enable {mode} mode",
  start_camera_test: "Start camera test",
  start_test_recording: "Start test recording",
  choose_service: "Choose a service",

  browser: "Browser",
  camera: "Camera",
  microphone: "Microphone",
  loudspeakers: "Loudspeakers",
  chrome_extension: "Chrome Extension",
  native_app: "Native App",
  network: "Network",

  msg: {
    something_went_wrong: "Something went wrong",
    logs_copied_to_clipboard: "Logs successfully copied to clipboard!",
    allow_camera_access_title: "Please allow access to your camera",
    allow_camera_access_description: "We need this in order to be able to check whether you can record video with your camera. Any data will not be saved or sent to any external server.",
    you_got_not_latest_chrome_version: "The latest version of Chrome for {osName} is {latestVersion} but you've got {currentVersion}.",
    cannot_get_access_to_test_service: "Cannot get access to the test service",
    please_install_link: "Please install {link}",
    you_are_offline: "You're offline",
    did_you_hear_question: "Did you hear?",
    speak_for_num_seconds: "speak for {num} seconds",

    check_your_connection: "Check you're on a strong connection.",
    we_recommend_num_mbps_upload_n_download: "We recommend at least {num}MBps available for both upload and download.",
    using_mobile_type_connection_not_recommended: "Using a mobile {type} connection is not recommended.",

    latest_version_of_chrome: "latest version of Chrome",
    your_chrome_is_out_of_date: "Your Chrome is out of date at the moment.",
    unsupported_browser: "Browser {name} is not supported.",
    item_not_supported_browser: "{item} is not fully supported by this browser.",
    cannot_get_access_to_browsers_navigator: "Cannot get access to the browser's navigator",

    camera_missing: "Camera missing",
    microphone_missing: "Microphone missing",
    speakers_missing: "Speakers missing",

    installed_question: "Installed?",
    check_for_installation_here: "Please check for the installation here",

    your_device_not_ready: "Your device is not ready!",
    your_device_ready_with_limitations: "Your device is ready with limitations.",
    your_device_ready_for_test: "Well done! Your device is ready for the user test.",

    result_instructions_check_email: "As soon as it’s time, please click on the “contact details” link in your emails to participate. You can close this tab now."
  },

  what_to_do: {
    title: "What to do now?",

    link_instructions_on_item: "instructions on {item}",

    steps: {
      1: "Try to fix each issue as instructed above. Then run the check again.",
      2: "If you still have issues, read our {link} and try to fix things accordingly.",
      3: "If nothing helps, please send us an email to {link}",
    }
  }
}
