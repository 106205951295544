export default class MessageBody {
  public userId!: string
  public lineupId!: string
  public serviceName!: 'lookback'|'zoom'
  public status!: 'success'|'warning'|'error'

  // stringified JSON
  public logs?: string = ''

  constructor (data: MessageBody) {
    Object.assign(this, data)
  }
}
