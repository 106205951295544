import DetectRTC from 'detectrtc'
import Checklist from '@/classes/Checklist'
import ChecklistItem from '@/classes/ChecklistItem'
import BrowserExtension from '@/types/BrowserExtension'
import NativeApp from '@/types/NativeApp'
import i18n, { Locales } from '@/i18n'

/**
 * Requirements
 * @link https://help.lookback.io/en/articles/1848320-what-are-the-browser-requirements-for-lookback-remote-liveshare-selftest-and-in-person-liveshare
 */
export default new Checklist({
  service: {
    id: 'lookback',
    name: 'Lookback'
  },
  links: {
    instructions: (): string => {
      switch (i18n.locale) {
        case Locales.fr: {
          return 'https://www.testingtime.com/fr/lookback-instructions'
        }
        case Locales.de: {
          return 'https://www.testingtime.com/lookback-anleitung'
        }
        default: {
          return 'https://www.testingtime.com/en/lookback-instructions'
        }
      }
    },
  },
  items: [
    new ChecklistItem({
      label: () => i18n.t('browser'),
      type: 'browser',
      priority: 'required',
      options: {
        browser: 'chrome',

        // iOS and Android Participants can use any browser to open the Participant link
        isAllowAnyMobileBrowser: true
      }
    }),

    new ChecklistItem({
      label: () => i18n.t('camera'),
      type: 'camera',
      priority: 'optional'
    }),

    new ChecklistItem({
      label: () => i18n.t('microphone'),
      type: 'microphone',
      priority: 'required',
      options: {
        allowRecordTest: false,
        recordingLengthMs: 3000
      }
    }),

    new ChecklistItem({
      label: () => i18n.t('loudspeakers'),
      type: 'speakers',
      priority: 'required',
      options: {
        recordingLengthMs: 3000
      }
    }),

    new ChecklistItem({
      label: () => `Participate by Lookback - ${i18n.t('chrome_extension')}`,
      type: 'extension',
      priority: 'required',
      isHidden: () => DetectRTC.isMobileDevice,
      options: {
        extension: {
          id: 'ppapgcbnefafdghpfglgilaghielefgn',
          browser: 'chrome',
          label: 'Participate by Lookback',
          url: 'https://chrome.google.com/webstore/detail/participate-by-lookback/ppapgcbnefafdghpfglgilaghielefgn'
        } as BrowserExtension
      }
    }),

    new ChecklistItem({
      label: () => i18n.t('native_app'),
      type: 'native-app',
      priority: 'required',
      isHidden: () => !DetectRTC.isMobileDevice,
      options: {
        apps: [
          {
            id: 'id1465098893',
            os: 'ios',
            label: 'Lookback Participate',
            URLSchemes: [
              'lookback-voy4://record'
            ],
            url: 'https://apps.apple.com/us/app/lookback-participate/id1465098893'
          },
          {
            id: 'io.lookback.participate',
            os: 'android',
            label: 'Lookback Participate',
            URLSchemes: [
              'intent://participate.lookback.com#Intent;scheme=https;package=io.lookback.participate;end',
              'lookback-am://record',
              'https://participate.lookback.io',
              'https://participate.lookback.com'
            ],
            url: 'https://play.google.com/store/apps/details?id=io.lookback.participate'
          }
        ] as NativeApp[]
      }
    }),

    new ChecklistItem({
      label: () => i18n.t('network'),
      type: 'network',
      priority: 'optional',
      options: {
        minDownloadMbps: 5,
        minUploadMbps: 5,
        accuracy: 1.5
      }
    })
  ]
})
