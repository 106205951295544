export enum Locales {
  en = 'en',
  fr = 'fr',
  de = 'de'
}

const LOCALES = {
  [Locales.en]: {
    label: 'English'
  },
  [Locales.fr]: {
    label: 'Français'
  },
  [Locales.de]: {
    label: 'Deutsch'
  }
}

export { LOCALES }
export default Locales
