import Vue from 'vue'
import VModal from 'vue-js-modal'

// shim to insulate apps from spec changes and prefix differences in WebRTC
import 'webrtc-adapter'

import store from '@/store'
import i18n from '@/i18n'
import App from '@/App.vue'
import '@/styles/skeleton.scss'
import '@/styles/index.scss'
import '@/polyfills'

Vue.use(VModal)
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
i18n.locale = store.state.locale;

(async () => {
  if (process.env.NODE_ENV === 'production') {
    await import('@/logger')
  }

  new Vue({
    store,
    i18n,
    render: (h) => h(App)
  }).$mount('#app')
})()
