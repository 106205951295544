





































import to from 'await-to-js'
import DetectRTC from 'detectrtc'
import { Component, Prop, Vue } from 'vue-property-decorator'
import NativeApp from '@/types/NativeApp'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'
import DialogMessage from './_dialogMessage.vue'

@Component({
  components: {
    Title,
    ValidationMessage,
    DialogMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist
  private isShowDialog: boolean = false
  private handlerClickYes: (() => void) | undefined
  private handlerClickNo: (() => void) | undefined

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (val) {
    this.$emit('input', {...this.item, status: val})
  }
  private get priority (): Priority {
    return this.item.priority
  }

  public check (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    return new Promise(async (resolve) => {
      let res

      const osName = (DetectRTC.osName as string).toLowerCase()
      const nativeApps: NativeApp[] =
        (this.item.options && this.item.options.apps) || []

      if (!nativeApps.length) return resolve()

      const nativeApp: NativeApp | undefined = nativeApps
        .find(app => app.os === osName)

      if (!nativeApp) return resolve()

      this.status = 'checking'
      this.isShowDialog = true

      this.item.validationResult = new ChecklistItemValidationResult({
        status: 'info',
        message: () => `<a href="${nativeApp.url}" class="text--info" target="blank" rel="external noopener">${this.$t('msg.check_for_installation_here')}</a>`,
        data: {
          nativeApp
        }
      })

      this.handlerClickYes = () => {
        this.isShowDialog = false
        res = new ChecklistItemValidationResult({
          status: 'success',
          data: {nativeApp}
        })
        resolve(res)
      }
      this.handlerClickNo = () => {
        this.isShowDialog = false
        res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => this.$t('msg.please_install_link', {
            link: `<a href="${nativeApp.url}" target="blank" rel="external noopener">${nativeApp.label}</a>`
          }),
          data: {
            nativeApp
          }
        })
        resolve(res)
      }
    })
  }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {},
        validationResult: null
      })
    }
    this.handlerClickYes = undefined
    this.handlerClickNo = undefined
    this.isShowDialog = false
  }
}
