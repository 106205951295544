import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Locales, { LOCALES } from './Locales'
import en from './en'
import fr from './fr'
import de from './de'

Vue.use(VueI18n)

const defaultLocale: Locales = Locales.en
export {
  LOCALES,
  Locales,
  defaultLocale
}
export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    [Locales.en]: en,
    [Locales.fr]: fr,
    [Locales.de]: de
  }
})
