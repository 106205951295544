




















import DetectRTC from 'detectrtc'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'
import DialogMessage from './_dialogMessage.vue'
import ModalCaptureCamera from './Modal.captureCamera.vue'

@Component({
  components: {
    Title,
    ValidationMessage,
    DialogMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist
  @Prop({default: 'auto'}) private mode!: 'manual' | 'auto'
  private isShowDialog: boolean = false
  private isCapturing: boolean = false
  private handlerClickTest: (() => void) | undefined
  private handlerClickYes: (() => void) | undefined
  private handlerClickNo: (() => void) | undefined

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (val) {
    this.$emit('input', {...this.item, status: val})
  }
  private get priority (): Priority {
    return this.item.priority
  }

  public check (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    return new Promise(async (resolve) => {
      if (this.mode === 'manual') {
        this.isShowDialog = true

        this.handlerClickTest = async () => {
          this.isShowDialog = false
          const res = await this.startVideoCapturing()
          resolve(res)
        }
      } else {
        const res = await this.startVideoCapturing()
        resolve(res)
      }

      // TODO: not in use
      // if (!DetectRTC.hasWebcam) {
      //   res = new ChecklistItemValidationResult({
      //     status: this.priority === 'required' ? 'error' : 'warning',
      //     message: () => this.$t('msg.camera_missing'),
      //     data: {
      //       hasWebcam: DetectRTC.hasWebcam
      //     }
      //   })
      //   return resolve(res)
      // }
    })
  }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {},
        validationResult: null
      })
    }

    this.isShowDialog = false
    this.isCapturing = false
    this.handlerClickYes = undefined
    this.handlerClickNo = undefined
    this.handlerClickTest = undefined
    this.stopVideoCapturing()
  }

  private async startVideoCapturing (): Promise<ChecklistItemValidationResult> {
    return new Promise(async (resolve) => {
      this.isCapturing = true

      this.handlerClickYes = () => {
        this.stopVideoCapturing()
        const res = new ChecklistItemValidationResult({
          status: 'success',
          data: {
            isWebsiteHasWebcamPermissions: DetectRTC.isWebsiteHasWebcamPermissions,
            hasWebcam: DetectRTC.hasWebcam
          }
        })
        resolve(res)
      }

      this.handlerClickNo = () => {
        this.stopVideoCapturing()
        const res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => this.$t('msg.camera_missing'),
          data: {
            isWebsiteHasWebcamPermissions: DetectRTC.isWebsiteHasWebcamPermissions,
            hasWebcam: DetectRTC.hasWebcam
          }
        })
        resolve(res)
      }

      this.$modal.show(ModalCaptureCamera, {
        handlerClickYes: this.handlerClickYes,
        handlerClickNo: this.handlerClickNo
      }, {
        classes: 'modal-capture-camera v--modal',
        clickToClose: true
      }, {

        // when closed by clicking on bg
        closed: () => this.handlerClickNo && this.handlerClickNo()
      })
    })
  }

  private async stopVideoCapturing (): Promise<void> {
    this.isCapturing = false
    this.handlerClickYes = undefined
    this.handlerClickNo = undefined
  }
}
