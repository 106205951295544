export default class ChecklistItemValidationResult {
  public status: ValidationResultStatus
  public message: string|(() => string) = ''
  public data: any

  // case -1: move to 1 step back
  // case 1: move to 1 step forward
  public step: number | undefined = undefined

  constructor (data: any) {
    this.status = data.status
    this.message = data.message
    this.data = data.data
    this.step = data.step
  }
}

export type ValidationResultStatus = 'success' | 'warning' | 'info' | 'error'
