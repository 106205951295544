






















import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class ModalCaptureCamera extends Vue {
  @Prop() private handlerClickYes!: (() => void)
  @Prop() private handlerClickNo!: (() => void)
  private uppy: any /* Uppy */ | undefined

  private mounted (): void {
    this.runCapturing()
  }
  private beforeDestroy (): void {
    this.stopCapturing()
  }

  private runCapturing (): void {
    this.uppy = Uppy()
    this.uppy.use(Webcam, {
      target: this.$refs.canvas,
      modes: ['video-only'],
      mirror: false,
      facingMode: 'user',
      locale: {
        strings: {
          // Title on the “allow access” screen
          allowAccessTitle: this.$t('msg.allow_camera_access_title'),
          // Description on the “allow access” screen
          allowAccessDescription: this.$t('msg.allow_camera_access_description')
        }
      }
    })
  }

  private stopCapturing (): void {
    this.uppy.close()
  }
}
