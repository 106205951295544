










import { Component, Prop, Vue } from 'vue-property-decorator'
import ChecklistItem, { Status } from '@/classes/ChecklistItem'

@Component
export default class ChecklistItemPart extends Vue {
  @Prop() private item!: ChecklistItem

  private get message (): string {
    const message = this.item?.validationResult?.message
    return typeof message === 'function'
      ? message()
      : message || ''
  }

  private get status (): Status {
    return this.item.status
  }
}
