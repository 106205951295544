import Vue from 'vue'
import Vuex from 'vuex'
import { defaultLocale } from '@/i18n'
import Locales from '@/i18n/Locales'
import i18n from '@/i18n'

Vue.use(Vuex)

export interface ModuleState {
  locale: Locales
}

export default new Vuex.Store({
  state: {
    locale: defaultLocale
  } as ModuleState,
  mutations: {
    SET_LOCALE: (state, payload: ModuleState['locale']) => {
      state.locale = payload
      i18n.locale = payload
    }
  },
  actions: {
  },
  modules: {
  },
})
