export default {
  brand_name: "TestingTime",
  app_name: "TechChecker",

  yes: "Ja",
  no: "Nein",
  check: "Überprüfen",
  stop: "Stoppen",
  works: "Funktioniert",
  doesnt_work: "Leider nein",

  check_my_device_for_title: "Mein Gerät überprüfen für:",
  enable_app_mode: "{mode} Modus einschalten",
  start_camera_test: "Kameratest starten",
  start_test_recording: "Mikrofontest starten",
  choose_service: "Wähle ein Tool",

  browser: "Browser",
  camera: "Kamera",
  microphone: "Mikrofon",
  loudspeakers: "Lautsprecher",
  chrome_extension: "Chrome Erweiterung",
  native_app: "Native App",
  network: "Internetverbindung",

  msg: {
    something_went_wrong: "Etwas ging schief",
    logs_copied_to_clipboard: "Logs wurden erfolgreich kopiert!",
    allow_camera_access_title: "Bitte gib dem Browser den Zugang zur Kamera frei",
    allow_camera_access_description: "Wir benötigen diesen Zugriff um sicherzustellen, dass die Kameraaufnahme funktioniert. Die Aufnahme wird nicht gespeichert oder an andere weitergeleitet.",
    you_got_not_latest_chrome_version: "Die neueste Version von Chrome auf {osName} ist {latestVersion}, aber du hast leider nur die Version {currentVersion}.",
    cannot_get_access_to_test_service: "Konnte keinen Zugang zum Service finden",
    please_install_link: "Bitte installiere es hier: {link}",
    you_are_offline: "Du hast keine Internetverbindung",
    did_you_hear_question: "Hast du die Aufnahme gehört?",
    speak_for_num_seconds: "Bitte sprich für {num} Sekunden",

    check_your_connection: "Stelle sicher, dass du eine gute Internetverbindung hast.",
    we_recommend_num_mbps_upload_n_download: "Wir empfehlen ein Minimum von {num}MBps, sowohl für Up&Download.",
    using_mobile_type_connection_not_recommended: "Eine mobile {type} Verbindung ist nicht zu empfehlen.",

    latest_version_of_chrome: "Neueste Version von Chrome",
    your_chrome_is_out_of_date: "Deine Chrome Version ist leider veraltet.",
    unsupported_browser: "Der Browser {name} ist nicht unterstützt.",
    item_not_supported_browser: "{item} ist von diesem Browser nicht vollständig unterstützt.",
    cannot_get_access_to_browsers_navigator: "Konnte keinen Zugriff auf den Navigator des Browsers finden.",

    camera_missing: "Kamera fehlt",
    microphone_missing: "Mikrofon fehlt",
    speakers_missing: "Lautsprecher fehlt",

    installed_question: "Installiert?",
    check_for_installation_here: "Bitte überprüfe die Installation hier",

    your_device_not_ready: "Leider ist dieses Gerät nicht bereit!",
    your_device_ready_with_limitations: "Dieses Gerät ist mit Einschränkungen in Ordnung.",
    your_device_ready_for_test: "Sehr gut! Dieses Gerät ist bereit für die Studie.",

    result_instructions_check_email: "Sobald es soweit ist, drücke auf den Teilnahmelink in deinem Email. Dieses Browserfenster kannst du schliessen."
  },

  what_to_do: {
    title: "Was nun?",

    link_instructions_on_item: "Anleitung für {item}",

    steps: {
      1: "Versuch die einzelnen Probleme wie oben beschrieben zu lösen. Dann kannst du den Test hier erneut durchführen.",
      2: "Falls du weiterhin Probleme hast, lese hier weiter {link} - dies sollte helfen.",
      3: "Falls gar nichts hilft, schreib uns an {link}",
    }
  }
}
