import Service from '@/types/Service'
import ChecklistItem from './ChecklistItem'

export default class Checklist {
  public service: Service
  public links: {[key: string]: string|(() => string)}
  public status: Status = 'none'
  public items: ChecklistItem[]

  constructor (data: any) {
    this.service = data.service
    this.links = data.links
    this.status = data.status || 'none'
    this.items = data.items
  }
}

export type Status = 'none' | 'checking' | 'completed'
