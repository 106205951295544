












































import ClipboardJS from 'clipboard'
import { Component, Vue, Watch } from 'vue-property-decorator'
import TechChecker from './components/TechChecker.vue'
import Checklist from '@/classes/Checklist'
import CONFIG from '@/CONFIG'
import Locales, { LOCALES } from '@/i18n/Locales'
import pkg from '../package.json'

@Component({
  components: {
    TechChecker
  }
})
export default class App extends Vue {
  public activeServiceId: string = 'lookback'
  public checklists: {[key: string]: Checklist} = CONFIG.CHECKLISTS
  private version: string = pkg.version
  private mode: 'manual' | 'auto' = 'auto'
  private LOCALES = LOCALES

  private get appLocale (): Locales {
    return this.$store.state.locale
  }

  private created (): void {
    window.addEventListener('hashchange', this.setMode, false)
    this.setMode()
    this.detectLocaleFromURL()
    this.onChangeLocale()
  }

  private mounted (): void {
    new ClipboardJS(this.$refs.version, {
      text: () => (console as any).getLogs()
    })
    .on('success', () => alert(this.$t('msg.logs_copied_to_clipboard')))
  }

  @Watch('appLocale')
  private onChangeLocale (): void {
    document.title = this.$t('app_name') + ' - ' + this.$t('brand_name')
  }

  private setMode (): void {
    const hash = window.location.hash
    this.mode = /manual$/.test(hash) ? 'manual' : 'auto'
  }

  private detectLocaleFromURL (): void {
    const locale: string|null = new URLSearchParams(window.location.search).get('lang')
    console.log('locale: ', locale)
    if (!locale) return
    if (!Object.keys(Locales).includes(locale)) {
      console.warn('Unsupported locale:', locale)
      return
    }
    this.$store.commit('SET_LOCALE', locale)
  }

  private onChangeService (serviceId: string): void {
    this.activeServiceId = serviceId
  }

  private onClickLocale (locale: Locales): void {
    this.$store.commit('SET_LOCALE', locale)
  }
}
