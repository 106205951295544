







import to from 'await-to-js'
import DetectRTC from 'detectrtc'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProgressObject, NetworkTestResult, runNetworkTestSpeedOfMe } from '@/utils'
import ChecklistItem, { Status, Priority } from '@/classes/ChecklistItem'
import Checklist from '@/classes/Checklist'
import ChecklistItemValidationResult from '@/classes/ChecklistItemValidationResult'
import Title from './_title.vue'
import ValidationMessage from './_validationMessage.vue'

interface NetworkOptions {
  minDownloadMbps: number
  minUploadMbps: number
  accuracy: number
}

@Component({
  components: {
    Title,
    ValidationMessage
  }
})
export default class ChecklistItemTmplt extends Vue {
  @Prop() private value!: ChecklistItem
  @Prop() private checklist!: Checklist

  private get item (): ChecklistItem {
    return this.value
  }
  private set item (val) {
    this.$emit('input', val)
  }

  private get status (): Status {
    return this.item.status
  }
  private set status (val) {
    this.$emit('input', {...this.item, status: val})
  }
  private get priority (): Priority {
    return this.item.priority
  }

  public check (): Promise<ChecklistItemValidationResult> {
    return this.checkSpeedOfMe()
  }

  public checkSpeedOfMe (): Promise<ChecklistItemValidationResult> {
    this.status = 'checking'

    return new Promise(async (resolve) => {
      let res
      let networkParams
      let err
      const options = this.item.options as NetworkOptions
      const DOWNLOAD_LIMIT_MBps = options.minDownloadMbps || 5
      const UPLOAD_LIMIT_MBps = options.minUploadMbps || 5
      const ACCURACY = options.accuracy || 1.5

      const navigator: any = window.navigator
      const connection = navigator.connection ||
        navigator.mozConnection ||
        null
      const connectionType = connection ? connection.effectiveType : ''

      if (!navigator) {
        res = new ChecklistItemValidationResult({
          status: 'error',
          message: () => this.$t('msg.cannot_get_access_to_browsers_navigator'),
          data: {
            err: this.$t('msg.cannot_get_access_to_browsers_navigator')
          }
        })
        return resolve(res)
      }

      if (!navigator.onLine) {
        res = new ChecklistItemValidationResult({
          status: 'error',
          message: () => this.$t('msg.you_are_offline'),
          data: {
            onLine: navigator.onLine
          }
        })
        return resolve(res)
      }


      // TODO: not in use
      // this is not accurate progress percentage, because of type "download"
      // has up to 11 "passes"
      // Read the doc: https://speedof.me/api/doc/SpeedOfMe_API_Doc.pdf
      // let progressUpload = 0
      // let progressDownload = 0
      const progressHandler = (data: ProgressObject): void | number => {
        // if (data.type === 'upload') progressUpload = data.percentDone
        // if (data.type === 'download') progressDownload = data.percentDone
        // const progress: number = (progressUpload + progressDownload) / (200 / 100)
        // return progress
      }

      [err, networkParams] = await to<NetworkTestResult>(
        runNetworkTestSpeedOfMe(1, progressHandler)
      )

      if (err || !networkParams) {
        res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => this.$t('msg.cannot_get_access_to_test_service'),
          data: {
            err,
            onLine: navigator.onLine
          }
        })
        return resolve(res)
      }

      const uploadMbps = networkParams.maxUpload
      const downloadMbps = networkParams.maxDownload

      if (downloadMbps < DOWNLOAD_LIMIT_MBps - ACCURACY || uploadMbps < UPLOAD_LIMIT_MBps - ACCURACY) {
        res = new ChecklistItemValidationResult({
          status: this.priority === 'required' ? 'error' : 'warning',
          message: () => [
            this.$t('msg.check_your_connection'),
            this.$t('msg.we_recommend_num_mbps_upload_n_download', {num: DOWNLOAD_LIMIT_MBps}),
          ].join(' '),
          data: {
            network: networkParams
          }
        })
        return resolve(res)
      }

      if (['slow-2g', '2g', '3g'].indexOf(connectionType) !== -1) {
        res = new ChecklistItemValidationResult({
          status: 'warning',
          message: () => [
            this.$t('msg.check_your_connection'),
            this.$t('msg.using_mobile_type_connection_not_recommended', {type: connectionType}),
          ].join(' '),
          data: {
            network: networkParams
          }
        })
        return resolve(res)
      }

      res = new ChecklistItemValidationResult({
        status: 'success',
        data: {
          network: networkParams
        }
      })
      return resolve(res)
    })
  }

  // TODO: not in use
  // async checkCustom () {
  //   const DOWNLOAD_LIMIT_MBps = 5
  //   const UPLOAD_LIMIT_MBps = 5

  //   const navigator: any = window.navigator
  //   const connection = navigator.connection ||
  //     navigator.mozConnection ||
  //     null
  //   const connectionType = connection ? connection.effectiveType : ''

  //   if (!navigator) {
  //     return new ChecklistItemValidationResult({
  //       status: 'error',
  //       message: () => 'Cannot get access to the browser\'s navigator',
  //       data: {}
  //     })
  //   }

  //   if (!navigator.onLine) {
  //     return new ChecklistItemValidationResult({
  //       status: 'error',
  //       message: () => 'You\'re offline',
  //       data: {}
  //     })
  //   }

  //   const upload: {kbps: NetworkSpeed} = await getUploadSpeed()
  //   const download: {kbps: NetworkSpeed} = await getDownloadSpeed()
  //   const uploadMbps = upload.kbps / 1024
  //   const downloadMbps = download.kbps / 1024

  //   console.log('Upload MBps: ', uploadMbps)
  //   console.log('Download MBps: ', downloadMbps)

  //   if (downloadMbps < DOWNLOAD_LIMIT_MBps - 1.5 || uploadMbps < UPLOAD_LIMIT_MBps - 1.5) {
  //     return new ChecklistItemValidationResult({
  //       status: 'warning',
  //       message: () => [
  //         'Check you\'re on a strong connection.',
  //         `We recommend at least ${DOWNLOAD_LIMIT_MBps}Mbps available for both upload and download.`
  //       ].join(' '),
  //       data: {}
  //     })
  //   }

  //   if (['slow-2g', '2g', '3g', '4g', '5g'].indexOf(connectionType) !== -1) {
  //     return new ChecklistItemValidationResult({
  //       status: 'warning',
  //       message: () => [
  //         'Check you\'re on a strong connection.',
  //         `Using a mobile ${connectionType} connection is not recommended.`
  //       ].join(' '),
  //       data: {}
  //     })
  //   }

  //   return false
  // }

  public reset (soft: boolean = true): void {
    if (!soft) {
      this.$emit('input', {
        ...this.item,
        status: 'none',
        cache: {},
        validationResult: null
      })
    }
  }
}
