


















import { Component, Prop, Vue } from 'vue-property-decorator'
import ChecklistItem, { Status } from '@/classes/ChecklistItem'

@Component
export default class ChecklistItemPart extends Vue {
  @Prop() private item!: ChecklistItem

  private get label (): string {
    return typeof this.item.label === 'function'
      ? this.item.label()
      : this.item.label
  }

  private get status (): Status {
    return this.item.status
  }

  private get statusIcon (): string {
    switch (this.status) {
      case 'checking':
        return 'loading-spinner xs'

      case 'success':
        return 'icon-checkbox-checked text--success'

      case 'warning':
        return 'icon-warning text--warning'

      case 'error':
        return 'icon-cross text--error'

      default:
        return 'icon-checkbox-unchecked'
    }
  }
}
